<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="col-md-12">
                <label class="tw-text-xs">{{ $t('warehouse.nameKh') }}</label>
                <input
                    v-model="model.location_name_kh"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('location_name_kh')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('location_name_kh')"
                >
                    {{ errors.first('location_name_kh') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('warehouse.nameEn')
                }}</label>
                <input
                    v-model="model.location_name_en"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('location_name_en')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('location_name_en')"
                >
                    {{ errors.first('location_name_en') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="required">{{ $t('warehouse.saleType') }}</label>
                <Select
                    v-model="model.sale_type"
                    class="tw-w-full"
                    filterable
                >
                    <Option
                        v-for="cus_type in posType"
                        :value="cus_type.value"
                        :key="cus_type.value"
                    >
                        {{ cus_type.label }}
                    </Option>
                </Select>
                <div class="validate tw-text-xs" v-if="errors.has('sale_type')">
                    {{ errors.first('sale_type') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ts-checkbox v-model="model.is_main_warehouse">{{
                    $t('warehouse.isMain')
                }}</ts-checkbox>
                <ts-checkbox v-model="model.is_transfer_approval_one_time">{{
                    $t('warehouse.isTransferApprovalOneTime')
                }}</ts-checkbox>
                <div
                    class="validate tw-text-xs"
                    v-if="errors.has('is_main_warehouse')"
                >
                    {{ errors.first('is_main_warehouse') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tw-mt-3 tw-text-right">
                <ts-button
                    class="tw-mr-2"
                    @click.prevent="() => $emit('cancel')"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    @click.prevent="onSave"
                    :waiting="waiting"
                    >{{ $t('save') }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                    >{{ $t('update') }}</ts-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'warehouseForm',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                location_id: 0,
                location_name_kh: null,
                location_name_en: null,
                sale_type: null,
                is_main_warehouse: false,
                is_transfer_approval_one_time: false
            }
        }
    },
    created () {
        this.setEditData()
    },
    computed: {
        ...mapState('stock/warehouse', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        posType () {
            return [
                { value: 'Retail', label: 'Retail Sale' },
                { value: 'Wholesale', label: 'Whole Sale' }
            ]
        }
    },
    methods: {
        onSave () {
            this.waiting = true
            this.$store
                .dispatch('stock/warehouse/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('stock/warehouse/update', {
                    id: this.model.location_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.model.location_id = 0
            this.model.location_name_kh = null
            this.model.location_name_en = null
            this.model.sale_type = null
            this.model.is_main_warehouse = false
            this.model.is_transfer_approval_one_time = false
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.location_id = this.edit_data.location_id
                this.model.location_name_kh = this.edit_data.location_name_kh
                this.model.location_name_en = this.edit_data.location_name_en
                this.model.sale_type = this.edit_data.sale_type
                this.model.is_main_warehouse = this.edit_data.is_main_warehouse
                this.model.is_transfer_approval_one_time =
                    this.edit_data.is_transfer_approval_one_time
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'WAREHOUSE',
                desc: not.text
            })
        }
    }
}
</script>
